import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PageList from '../components/PageList';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ProjectList = ({ data }) => {
  return (
    <Layout>
      <Helmet title={'Tom Beyer: Projects'} />
      <div>
        <h1 className="pageTitle">Projects</h1>
        <PageList edges={data.allMarkdownRemark.edges} />
      </div>
    </Layout>
  );
};

export default ProjectList;

ProjectList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

ProjectList.defaultProps = {
  data: false,
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
